import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import {
    AutoSizer,
    CellMeasurerCache,
    CellMeasurer,
    WindowScroller,
    createMasonryCellPositioner,
    Masonry,
} from 'react-virtualized';

import './Gallery.styl';
import { altify } from '../../../utils/strings';
import LocalizedLink from '../LocalizedLink';

class Gallery extends PureComponent {
    constructor(props) {
        super(props);

        this._gutterSize = 20;
        this._columnCount = 0;
        this._columnWidth = 200;

        this._cache = new CellMeasurerCache({
            defaultHeight: 250,
            defaultWidth: 200,
            fixedWidth: true,
        });

        this.state = {
            overscanByPixels: 0,
            img: null,
        };

        this._cellRenderer = this._cellRenderer.bind(this);
        this._onResize = this._onResize.bind(this);
        this._setMasonryRef = this._setMasonryRef.bind(this);
        this._reset = this._reset.bind(this);
    }

    _setMasonryRef(ref) {
        this._masonry = ref;
    }

    _calculateColumnCount() {
        const brakePoints = [250, 640, 768, 920, 1200, 1400, 1800];

        this._columnCount =
            brakePoints.reduceRight((prev, curr, index) => {
                return curr < this._width ? prev : index;
            }, brakePoints.length) + 1;

        this._columnWidth = Math.floor(
            (this._width - (this._columnCount - 1) * this._gutterSize) /
                this._columnCount,
        );
    }

    _resetCellPositioner() {
        this._cellPositioner.reset({
            columnCount: this._columnCount,
            columnWidth: this._columnWidth,
            spacer: this._gutterSize,
        });
    }

    _onResize({ width }) {
        this._width = width;
        this._gutterSize = width < 767 ? 10 : 20;
        this._reset();
    }

    _reset() {
        this._calculateColumnCount();
        this._cache.clearAll();
        this._resetCellPositioner();
        this._masonry.clearCellPositions();
    }

    _cellRenderer({ index, key, parent, style }) {
        const { list, originalPath } = this.props;
        style.width = this._columnWidth;

        const img = {
            name: list[index].node.name,
            collection: list[index].node.relativeDirectory,
            data: list[index].node.childImageSharp.fluid,
        };

        return (
            <CellMeasurer
                cache={this._cache}
                index={index}
                key={key}
                parent={parent}
            >
                <LocalizedLink
                    className='cell'
                    // role="button"
                    // tabIndex={0}
                    style={style}
                    to={originalPath + list[index].node.id + '/'}
                    state={{
                        prevPath: originalPath,
                        key: window.history.state.key,
                    }}
                >
                    <div className='invisible' id={list[index].node.id} />
                    <Img
                        fluid={img.data}
                        alt={altify(`${img.collection}-${img.name}`)}
                        fadeIn
                        objectFit='cover'
                        objectPosition='50% 50%'
                        className='masonry-img'
                    />
                </LocalizedLink>
            </CellMeasurer>
        );
    }

    _initCellPositioner() {
        if (typeof this._cellPositioner === 'undefined') {
            this._cellPositioner = createMasonryCellPositioner({
                cellMeasurerCache: this._cache,
                columnCount: this._columnCount,
                columnWidth: this._columnWidth,
                spacer: this._gutterSize,
            });
        }
    }

    render() {
        const { overscanByPixels } = this.state;
        const { list } = this.props;
        return (
            <div className='gallery-container'>
                <WindowScroller>
                    {({ height, scrollTop }) => (
                        <AutoSizer
                            disableHeight
                            height={height}
                            onResize={this._onResize}
                            scrollTop={scrollTop}
                        >
                            {({ width }) => {
                                this._width = width;

                                this._calculateColumnCount();
                                this._initCellPositioner();

                                return (
                                    <Masonry
                                        autoHeight={true}
                                        cellCount={list.length}
                                        cellMeasurerCache={this._cache}
                                        cellPositioner={this._cellPositioner}
                                        cellRenderer={this._cellRenderer}
                                        height={height}
                                        overscanByPixels={overscanByPixels}
                                        ref={this._setMasonryRef}
                                        scrollTop={scrollTop}
                                        width={width}
                                    />
                                );
                            }}
                        </AutoSizer>
                    )}
                </WindowScroller>
            </div>
        );
    }
}

Gallery.propTypes = {
    list: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    originalPath: PropTypes.string.isRequired,
};

export default Gallery;
