export const PRODUCT_TYPE_CARD = 'card';
export const PRODUCT_TYPE_WRAP = 'wrap';
export const PRODUCT_TYPE_ITEM = 'item';

export const collections = {
    card: {
        // Fashion
        aurore: 'Aurore',
        'c-est-chic': "C'est Chic",
        click: 'Click',
        'en-lettres-d-or': "En Lettres d'Or",
        gourmandiz: 'Gourmandiz',
        'happy-word': 'Happy Word',
        'serie-d-artiste': "Série d'Artiste",
        'very-chic': 'Very Chic',
        'pop-cart': "Pop C'Art",
        // Generaliste
        collages: 'Coll’Âge',
        extravagance: 'Extravagance',
        'grand-comme-ca': 'Grand Comme Ca !',
        'le-bel-age-en-rimes': 'Le Bel Age en Rimes',
        'mise-en-scene': 'Mise en Scene',
        'poeme-du-jour': 'Poeme du Jour',
        'porte-billets': 'Porte Billets',
        'post-script-homme': 'Post Script Homme',
        sparkle: 'Sparkle',
        'stick-cartes': "Stick'Cartes",
        'un-petit-mot-vaut-mieux-quun-long-discours':
            "Un petit mot vaut mieux qu'un long discours",
        // Humour
        'cha-cha-cha': 'Cha-Cha-Cha',
        'echec-et-mat': 'Echec et mat',
        'farce-attrape': 'Farce et attrape',
        'la-x-et-la-maniere': 'La X et la Manière',
        'geante-maxi-geante_showlapin-chachacha': 'Géante Maxi Géante',
        licorne: 'A la Folie Licornement',
        'show-lapin': 'Show Lapin',
        soundcart: 'Soundcards',
        voutch: 'Voutch',
        // Tendresse
        aerial: 'Aerial',
        'comme-un-reve': 'Comme un rêve',
        'jardin-anglais': 'Jardin Anglais',
        paradisio: 'Paradisio',
        'sugar-candy': 'Sugar Candy',
        // Unicef
        unicef: 'Unicef',
        // Mural
        mural: 'Mural',
        // Enfant
        dreamworks: 'Dreamworks',
        'le-petit-monde-de-kali': 'Le petit monde de Kali',
        // Claire Vision
        'de-profundis': 'De Profundis',
        'd-ici-et-d-ailleurs': 'D’ici et d’ailleurs',
        'animaux-photos': 'Animaux photos',
        'cartes-textes': 'Cartes textes',
    },
    wrap: {
        animania: 'Animania',
        aquacolor: 'Aquacolor',
        celeste: 'Celeste',
        comics: 'Comics',
        gentleman: 'Gentleman',
        gourmandise: 'Gourmandise',
        'happy-kids': 'Happy Kids',
        'les-unis': 'Les Unis',
        love: 'Love',
        miniatures: 'Miniatures',
        paradisio: 'Paradisio',
        pretty: 'Pretty',
        spring: 'Spring',
        'ultra-chic': 'Ultra Chic',
    },
    item: {
        'mes-ballons-dannivresaire': "Mes Ballons d'Anniversaire",
        'mes-bougies-danniversaire': "Mes Bougies d'Anniversaire",
        'mon-joli-petit-bijou': 'Mon Joli Petit Bijou',
        'mon-joli-stylo': 'Mon Joli Stylo',
        'mon-porte-cles': 'Mon Porte-clés',
    },
};
