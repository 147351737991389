import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/styles";

import customTabsStyle from "../../../jss/components/CustomTabs";

import "./Filters.styl";

const ColorPaper = withStyles(({ palette }) => ({
    root: {
        backgroundColor: palette.primary.main,
        padding: "1rem 16px",
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0
    }
}))(Paper);

const Filters = ({ filters, handleChange, value, classes }) => {
    return (
        <ColorPaper className="filters-bloc">
            <Tabs
                className="filters-list"
                classes={{
                    root: classes.customTabsRoot,
                    flexContainer: classes.tabsContainer,
                    indicator: classes.displayNone
                }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="inherit"
                // variant="scrollable"
                centered
                // scrollButtons="off"
            >
                {filters.map(filter => (
                    <Tab
                        key={`filter-${filter.tag}`}
                        className="filter-item"
                        classes={{
                            root: classes.customTabRoot,
                            selected: classes.customTabSelected,
                            wrapper: classes.customTabWrapper
                        }}
                        label={<FormattedMessage id={filter.name} />}
                        value={filter.tag}
                    />
                ))}
            </Tabs>
        </ColorPaper>
    );
};

Filters.propTypes = {
    filters: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(customTabsStyle)(Filters);
