export const FILTER_ALL = "all";
export const FILTER_ENFANT = "enfant";
export const FILTER_GENERAL = "generaliste";
export const FILTER_TENDRESSE = "tendresse";
export const FILTER_FASHION = "fashion";
export const FILTER_HUMOUR = "humour";
export const FILTER_MURAL = "mural";
export const FILTER_UNICEF = "unicef";
export const FILTER_CLAIRE_VISION = "claireVision";
// export const FILTER_FIN_ANNEE = "finAnnee";

export const CARTERIE_FILTERS = [
    {
        name: "filter.all",
        tag: FILTER_ALL
    },
    {
        name: "filter.carterie.enfant",
        tag: FILTER_ENFANT
    },
    {
        name: "filter.carterie.general",
        tag: FILTER_GENERAL
    },
    {
        name: "filter.carterie.tendresse",
        tag: FILTER_TENDRESSE
    },
    {
        name: "filter.carterie.fashion",
        tag: FILTER_FASHION
    },
    {
        name: "filter.carterie.humour",
        tag: FILTER_HUMOUR
    },
    {
        name: "filter.carterie.mural",
        tag: FILTER_MURAL
    },
    {
        name: "filter.carterie.unicef",
        tag: FILTER_UNICEF
    },
    {
        name: "filter.carterie.claire-vision",
        tag: FILTER_CLAIRE_VISION
    }
    // {
    //     name: "filter.carterie.finAnnee",
    //     tag: FILTER_FIN_ANNEE
    // }
];
