/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import MainLayout from '../views/layouts/MainLayout';
import SEO from '../views/components/SEO';
import Parallax from '../views/components/Parallax';
import Gallery from '../views/components/Masonry';
import Filters from '../views/components/Filters';
import {
    CARTERIE_FILTERS,
    FILTER_ALL,
    FILTER_ENFANT,
    FILTER_MURAL,
} from '../constants/filters';
import { PRODUCT_TYPE_CARD } from '../constants/products';

const GiftCardPage = ({
    pageContext: { originalPath, locale },
    location,
    data,
}) => {
    const {
        fashion,
        generaliste,
        humour,
        tendresse,
        unicef,
        mural,
        muralEnfant,
        enfantBase,
        enfantSupp,
        claireVision,
    } = data;
    const fullMural = [...mural.edges, ...muralEnfant.edges];
    const fullEnfant = [
        ...enfantBase.edges,
        ...enfantSupp.edges,
        ...muralEnfant.edges,
    ];
    const fullList = [
        ...fashion.edges,
        ...generaliste.edges,
        ...humour.edges,
        ...tendresse.edges,
        ...unicef.edges,
        ...mural.edges,
        ...enfantBase.edges,
        ...claireVision.edges,
    ];
    const [filter, setFilter] = useState({ name: FILTER_ALL, list: fullList });

    const handleChange = (e, newValue) => {
        let list;

        switch (newValue) {
            case FILTER_ALL:
                list = fullList;
                break;
            case FILTER_ENFANT:
                list = fullEnfant;
                break;
            case FILTER_MURAL:
                list = fullMural;
                break;

            default:
                list = data[newValue].edges;
                break;
        }

        setFilter({
            name: newValue,
            list,
        });
    };

    return (
        <MainLayout
            locale={locale}
            originalPath={originalPath}
            location={location}
        >
            <SEO
                title='pages.card.headerTitle'
                description='pages.card.metaDescription'
                originalPath={originalPath}
                location={location}
                metaIcon={data.seoImage.childImageSharp.resize.src}
            />
            <div className='page-bg'>
                <Parallax
                    img={data.coverImage.childImageSharp.fluid}
                    alt='image-carterie-groupe-editor'
                    overlay='light-bottom-left'
                >
                    <div className='page-intro gallery'>
                        <div className='container'>
                            <h1>
                                <FormattedMessage id='pages.card.title' />
                            </h1>
                            <p>
                                <FormattedMessage id='pages.card.description' />
                            </p>
                            <p>
                                <FormattedMessage id='pages.card.description2' />
                            </p>
                        </div>
                    </div>
                </Parallax>
                <div className='page-content gallery'>
                    <Filters
                        filters={CARTERIE_FILTERS}
                        handleChange={handleChange}
                        value={filter.name}
                    />
                    <div className='container filters'>
                        <Gallery
                            key={filter.name}
                            list={filter.list}
                            fluid
                            originalPath={originalPath}
                            type={PRODUCT_TYPE_CARD}
                        />
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

GiftCardPage.propTypes = {
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        originalPath: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default GiftCardPage;

// allFile(filter: {sourceInstanceName: {eq: "giftCards"}}) {
//     group(field: relativeDirectory) {
//       fieldValue
//       totalCount
//       edges {
//         node {
//           id
//           relativeDirectory
//           name
//           ext
//         }
//       }
//     }
//   }

export const query = graphql`
    query {
        coverImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "lettre-dor-mains.jpg" }
        ) {
            ...BgImages
        }
        seoImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "lettre-dor-mains.jpg" }
        ) {
            ...SeoImages
        }
        fashion: allFile(
            filter: {
                sourceInstanceName: { eq: "giftCards" }
                relativeDirectory: { regex: "/fashion/" }
            }
        ) {
            ...MasonryImages
        }
        generaliste: allFile(
            filter: {
                sourceInstanceName: { eq: "giftCards" }
                relativeDirectory: { regex: "/generaliste/" }
            }
        ) {
            ...MasonryImages
        }
        humour: allFile(
            filter: {
                sourceInstanceName: { eq: "giftCards" }
                relativeDirectory: { regex: "/humour/" }
            }
        ) {
            ...MasonryImages
        }
        tendresse: allFile(
            filter: {
                sourceInstanceName: { eq: "giftCards" }
                relativeDirectory: { regex: "/tendresse/" }
            }
        ) {
            ...MasonryImages
        }
        unicef: allFile(
            filter: {
                sourceInstanceName: { eq: "giftCards" }
                relativeDirectory: { regex: "/unicef/" }
            }
        ) {
            ...MasonryImages
        }
        mural: allFile(
            filter: {
                sourceInstanceName: { eq: "giftCards" }
                relativeDirectory: { eq: "mural/mural" }
            }
        ) {
            ...MasonryImages
        }
        muralEnfant: allFile(
            filter: {
                sourceInstanceName: { eq: "giftCards" }
                relativeDirectory: { regex: "/enfant/mural/" }
            }
        ) {
            ...MasonryImages
        }
        enfantBase: allFile(
            filter: {
                sourceInstanceName: { eq: "giftCards" }
                relativeDirectory: { regex: "/enfant/base/" }
            }
        ) {
            ...MasonryImages
        }
        enfantSupp: allFile(
            filter: {
                sourceInstanceName: { eq: "giftCards" }
                relativeDirectory: { regex: "/enfant/supp/" }
            }
        ) {
            ...MasonryImages
        }
        claireVision: allFile(
            filter: {
                sourceInstanceName: { eq: "giftCards" }
                relativeDirectory: { regex: "/claire-vision/" }
            }
        ) {
            ...MasonryImages
        }
    }
`;
